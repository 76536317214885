import React from 'react';

export const REGEX = /^[A-Za-zÄÆÖØÅäæöøå]+$/;
export const ERROR_MESSAGES = {
  required: 'This word is required',
  maxLength: 'Word cannot be longer than 12 characters',
  pattern: 'Must be a single word and only contain letters',
  validate: 'No profanity!',
};

type WordErrorType = 'required' | 'maxLength' | 'pattern' | 'validate';

interface ErrorProps {
  errorType: WordErrorType;
}

function Error({ errorType }: ErrorProps): JSX.Element|null {
  if (errorType in ERROR_MESSAGES) {
    return (
      <span
        className={`
          text-xxs md:text-xs text-red-500 mt-2
        `}
      >
        {ERROR_MESSAGES[errorType]}
      </span>
    );
  }

  return null;
}

export default Error;
