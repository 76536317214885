import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import GeneratedSnippetPlayer from '../components/GeneratedSnippetPlayer';
import LogoHeader from '../components/LogoHeader';
import YourLyrics from '../components/YourLyrics';
import { SNIPPETS_TABLE_URL } from '../config';
import { SnippetsTableItem } from '../types/words';
import SiteUIContainer from './SiteUIContainer';

interface ViewProps {
  itemId: string;
}

function View({ itemId }: ViewProps): JSX.Element {
  const [item, setItem] = useState<SnippetsTableItem|null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getSnippet = async (): Promise<void> => {
      const snippetResponse = await axios(`${SNIPPETS_TABLE_URL}/${itemId}`, {
        method: 'get',
      });

      if (snippetResponse.status === 200 && snippetResponse.data?.Item) {
        setItem(snippetResponse.data.Item);
      }

      setIsLoading(false);
    };

    getSnippet();
  }, [itemId]);

  return (
    <SiteUIContainer>
      <div className="w-full h-full flex flex-col justify-between px-5 md:p-0">
        <LogoHeader />
        <div className="flex flex-col items-center">
          {!item ? (
            <h2 className="mb-10 text-center text-xl">
              {isLoading ? 'Loading...' : 'This page doesn\'t exist.'}
            </h2>
          ) : (
            <>
              <YourLyrics
                chosenWords={{
                  wordOne: item.wordOne,
                }}
              />
              <GeneratedSnippetPlayer url={item.url} />
            </>
          )}
          <div className="flex justify-center w-full">
            <a
              href="/"
              className={`
                mt-10 px-5 py-1 w-80 text-center
                bg-primaryDarker text-secondary uppercase
              `}
            >
              Generate your own f*ck you
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </SiteUIContainer>
  );
}

export default View;
