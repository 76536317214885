import React from 'react';

interface StepTitleProps {
  text: string;
}

function StepTitle({ text }: StepTitleProps): JSX.Element {
  return (
    <h2 className="text-xl md:text-2xl text-primaryDarker mb-2 uppercase">
      {text}
    </h2>
  );
}

export default StepTitle;
