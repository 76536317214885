import { TEXT_TO_SPEECH } from '../config';
import { BlobUrlWithRevoke } from '../types/misc';
import { createBlobUrlObjectFromBlob } from './misc';

const getTextToSpeechSampleAsBlob = async (word: string): Promise<Blob> => {
  const textToSpeechResponse = await fetch(`${TEXT_TO_SPEECH}/${word}`);
  const responseBlob = await textToSpeechResponse.blob();

  return responseBlob;
};

export const getTextToSpeechSampleBlobUrlObject = async (
  word: string,
): Promise<BlobUrlWithRevoke> => {
  const blob = await getTextToSpeechSampleAsBlob(word);
  const blobUrlWithRevoke = createBlobUrlObjectFromBlob(blob);

  return blobUrlWithRevoke;
};
