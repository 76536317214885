import React, { ReactElement } from 'react';

interface SiteUIContainerProps {
  children: ReactElement;
}

function SiteUIContainer({ children }: SiteUIContainerProps): JSX.Element {
  return (
    <>
      <section className="hidden md:block md:w-17/32 md:h-auto bg-hero bg-center bg-cover" />
      <section className="md:w-15/32 bg-secondary text-primary min-h-full">
        {children}
      </section>
    </>
  );
}

export default SiteUIContainer;
