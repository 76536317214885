import React, { useState } from 'react';
import AnnouncementMessage from '../components/AnnouncementMessage';
import ChooseWords from '../components/ChooseWords';
import Footer from '../components/Footer';
import LogoHeader from '../components/LogoHeader';
import { isIOS } from '../lib/browser';
import { Words } from '../types/words';
import SiteUIContainer from './SiteUIContainer';
import SnippetContainer from './SnippetContainer';

function Home(): JSX.Element {
  const [chosenWords, setChosenWords] = useState<Words|null>(null);

  const onSubmit = (data: Words): void => {
    setChosenWords(data);
  };

  const backToStepOneOnClick = (): void => setChosenWords(null);

  return (
    <SiteUIContainer>
      <>
        {!chosenWords && <div className="w-full h-88 bg-hero bg-center bg-cover md:hidden" />}
        <div className={`w-full ${chosenWords ? 'h-full' : 'h-auto md:h-full'} flex flex-col justify-between px-5 md:p-0 min-h-580px`}>
          {!chosenWords ? (
            <>
              <div />
              <ChooseWords onSubmit={onSubmit} />
            </>
          ) : (
            <>
              <header className="flex flex-col relative mb-2">
                <LogoHeader />
                {isIOS && <AnnouncementMessage text="No sound? Please check your device is not in silent mode" />}
              </header>
              <SnippetContainer
                chosenWords={chosenWords}
                backToStepOneOnClick={backToStepOneOnClick}
              />
            </>
          )}
          <Footer />
        </div>
      </>
    </SiteUIContainer>
  );
}

export default Home;
