export const APP_STATE = {
  INITIAL: 'INITIAL',
  LISTEN: 'LISTEN',
  RECORDING: 'RECORDING',
  UPLOADING: 'UPLOADING',
  GENERATED: 'GENERATED',
} as const;

export type AppStates = typeof APP_STATE;
export type AppState = AppStates[keyof AppStates];
