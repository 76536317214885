import React from 'react';
import ReactLoading from 'react-loading';
import StepTitle from './StepTitle';

interface LoadingProps {
  text: string;
}

function Loading({ text }: LoadingProps): JSX.Element {
  return (
    <>
      <StepTitle text={text} />
      <ReactLoading type="spinningBubbles" color="#FC6E00" className="mt-6" />
    </>
  );
}

export default Loading;
