import { faPauseCircle, faPlayCircle, faStopCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface PlayerButtonsProps {
  isPlaying: boolean;
  playPause: () => void;
  stop: () => void;
}

function PlayerButtons({
  isPlaying, playPause, stop,
}: PlayerButtonsProps): JSX.Element {
  return (
    <div className="flex justify-center text-primaryDarker">
      <button
        type="button"
        className="ny-2 mx-5"
        onClick={playPause}
        custom-link-name="Play Button"
      >
        <FontAwesomeIcon
          icon={isPlaying ? faPauseCircle : faPlayCircle}
          className="text-4xl"
        />
      </button>
      <button
        type="button"
        className="ny-2 mx-5"
        onClick={stop}
        custom-link-name="Stop Button"
      >
        <FontAwesomeIcon
          icon={faStopCircle}
          className="text-4xl"
        />
      </button>
    </div>
  );
}

export default PlayerButtons;
