import React from 'react';
import gayleLogo from '../assets/img/logo.png';
import { Words } from '../types/words';
import WordsForm from './WordsForm';

interface ChooseWordsProps {
  onSubmit: (data: Words) => void
}

function ChooseWords({ onSubmit }: ChooseWordsProps): JSX.Element {
  return (
    <div className="flex flex-col justify-center">
      <img
        src={gayleLogo}
        alt="GAYLE logo"
        className="w-3/4 xs:w-3/5 mx-auto mb-1"
      />
      <h1 className="text-center text-2xl mt-3">
        {/* ABCDEFU */}
      </h1>
      <h2 className="text-center font-secondary text-sm mt-2">
        Generate your own F*CK YOU!
      </h2>
      <WordsForm onSubmit={onSubmit} />
    </div>
  );
}

export default ChooseWords;
