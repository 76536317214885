const API_URL = process.env.NODE_ENV === 'development'
  ? 'https://s0qcleneli.execute-api.eu-west-2.amazonaws.com' // ned account
  : 'https://s0qcleneli.execute-api.eu-west-2.amazonaws.com'; // ned account
  // : 'https://2jf3knolnc.execute-api.eu-west-2.amazonaws.com'; // wmg account

export const GET_SIGNED_URL = `${API_URL}/uploads`;

export const SNIPPETS_TABLE_URL = `${API_URL}/items`;

export const CREATE_SNIPPET_VIDEO = `${API_URL}/video`;

export const TEXT_TO_SPEECH = `${API_URL}/speech`;

export const AUDIO_FILE_LOCATION = '/audio/gayle-1-input-version-v2.mp3';
