/* eslint-disable no-console */
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as LoadingBubbles } from '../assets/img/icon-bubbles.svg';
import { ReactComponent as DownloadIcon } from '../assets/img/icon-download.svg';
import { CREATE_SNIPPET_VIDEO } from '../config';
import { isIOS } from '../lib/browser';

interface ArtworkVideoButtonProps {
  snippetId: string;
}

interface ArtworkVideoButtonState {
  loading: boolean;
  data: { videoUrl: string }|null;
  error: string|null;
}

function ArtworkVideoButton({ snippetId }: ArtworkVideoButtonProps): JSX.Element|null {
  const [state, setState] = useState<ArtworkVideoButtonState>({
    loading: true,
    data: null,
    error: null,
  });

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    let isUnmounted = false;

    const load = async (): Promise<void> => {
      try {
        const createSnippetVideoUrl = `${CREATE_SNIPPET_VIDEO}/${snippetId}`;
        const createSnippetVideoResponse = await axios.get(createSnippetVideoUrl, {
          signal: controller.signal,
        });

        const { data } = createSnippetVideoResponse;

        if (!isUnmounted) {
          if (createSnippetVideoResponse.status === 200) {
            setState({ loading: false, error: null, data });
          } else {
            setState({ loading: false, data: null, error: data });
          }
        }
      } catch (error) {
        console.log(error);

        if (!isUnmounted) {
          const errorMessage = error instanceof Error
            ? error.message
            : 'Unknown error';

          setState({ loading: false, data: null, error: errorMessage });
        }
      }
    };

    load();

    return () => {
      isUnmounted = true;
      controller.abort();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.loading) {
    return (
      <p
        className={`
          w56 mt-5 pl-2 py-0.5 w-56
          bg-primaryDarker text-secondary uppercase
          flex justify-center items-center text-sm select-none
        `}
      >
        Generating video
        <LoadingBubbles width="40px" viewBox="0 6 32 20" className="ml-2" />
      </p>
    );
  }

  if (state.error) {
    return (
      <p
        className={`
          w56 mt-5 px-5 py-0.5 w-56 bg-primaryDarker 
          text-secondary uppercase text-center text-sm select-none
        `}
      >
        There was an error generating your video
      </p>
    );
  }

  if (state.data) {
    return (
      <div className="flex flex-col items-center mt-5 w-66">
        {isIOS && (
          <>
            <span className="text-xs text-center w-66 pb-2">
              If you don&apos;t see the video in your Photos app:
            </span>
            <ul className="text-xs text-left list-disc w-56 pl-1 pb-2">
              <li className="mb-1">Tap DOWNLOAD VIDEO below and select Download</li>
              <li className="mb-1">
                Tap the download icon next to &quot;A
                <span className="text-sm">A</span>
                &quot; on the left of the address bar
              </li>
              <li className="mb-1">Go to Downloads and open the video</li>
              <li className="mb-1">To add to your Photos app, tap the share icon and select Save Video</li>
            </ul>
          </>
        )}
        <div className="relative">
          <a
            className={`
            px-5 py-0.5 w-56 mt-2
            bg-primaryDarker text-secondary uppercase
            flex justify-center items-center select-none whitespace-nowrap
          `}
            href={state.data?.videoUrl}
            // target="_blank"
            // rel="noreferrer"
            // download
            // remove download attribute due to iOS error.
            // use ContentDisposition header on file instead.
            custom-link-name="Artwork Video Download Button"
          >
            Download video
            {' '}
            <DownloadIcon className="ml-5" />
          </a>
        </div>
        <div className="mt-5 relative">
          <CopyToClipboard
            text={state.data?.videoUrl}
            onCopy={async () => setIsCopied(true)}
          >
            <button
              className={`
                w56 px-5 py-0.5 w-56
                bg-primaryDarker text-secondary uppercase
                flex justify-center items-center select-none whitespace-nowrap
              `}
              type="button"
            >
              Copy link to video
              {' '}
              <FontAwesomeIcon icon={faCopy} className="text-lg ml-4" />
            </button>
          </CopyToClipboard>
          {isCopied && <span className="absolute text-xs -right-14 top-1/2 -translate-y-1/2">Copied!</span>}
        </div>
      </div>
    );
  }

  return null;
}

export default ArtworkVideoButton;
