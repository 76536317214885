import React from 'react';
import FooterIcons from './FooterIcons';

function Separator(): JSX.Element {
  return (
    <span className="mx-2 my-1 text-tertiary">
      &middot;
    </span>
  );
}

interface FooterProps {
  fixed?: boolean;
}

function Footer({ fixed = false }: FooterProps): JSX.Element {
  return (
    <footer
      className={`
        ${fixed ? 'fixed' : 'self-end'} w-full md:w-r50
        flex flex-col justify-center items-center
        md:pb-2 px-2 bottom-0 z-10
        font-secondary md:mt-5
      `}
    >
      <FooterIcons />
      <ul className="flex flex-wrap justify-center text-xxs">
        <li>
          <span className="text-tertiary">
            &copy; 2022 Warner Music UK Limited
          </span>
          <Separator />
        </li>
        <li>
          <a
            href="https://www.wminewmedia.com/privacy/"
            className="text-tertiary"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <Separator />
        </li>
        <li>
          <a
            href="https://www.wminewmedia.com/terms-of-use/"
            className="text-tertiary"
            target="_blank"
            rel="noreferrer"
          >
            Terms Of Use
          </a>
          <Separator />
        </li>
        <li>
          <a
            href="https://www.wminewmedia.com/cookies-policy/"
            className="text-tertiary"
            target="_blank"
            rel="noreferrer"
          >
            Cookies Policy
          </a>
          <Separator />
        </li>
        <li>
          <span
            className="ot-sdk-show-settings text-tertiary"
            style={{ cursor: 'pointer' }}
          >
            Cookies Settings
          </span>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
