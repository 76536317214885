/* eslint-disable react/jsx-props-no-spreading */
import Filter from 'bad-words';
import React, { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { Words } from '../types/words';
import Error, { ERROR_MESSAGES, REGEX } from './FormErrors';

const filter = new Filter();

interface ReactChildrenProps { children: ReactNode; }

function InputWrapper({ children }: ReactChildrenProps): JSX.Element {
  return (
    <div className="inline-block pr-5 relative">{children}</div>
  );
}

const formOptionsForWordInput = {
  required: true,
  maxLength: 12,
  pattern: {
    value: REGEX,
    message: ERROR_MESSAGES.pattern,
  },
  validate: (word: string) => !filter.isProfane(word),
};
interface WordsFormProps {
  onSubmit: (data: Words) => void
}

function WordsForm({ onSubmit }: WordsFormProps): JSX.Element {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const inputClass = `
    bg-secondary text-primary border border-primaryDarker
    placeholder-primary w-32 md:w-44 text-center uppercase word-spacing-md
  `;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col justify-center items-center uppercase mb-5 md:mb-0"
    >
      <div
        className={`
          flex flex-col w-full justify-center py-10
          pl-10per xs:pl-10per lg:pl-10per 2xl:pl-15per
          text-base md:text-xl
          word-spacing-md line-height-lg sm:line-height-xl
        `}
      >
        <span className="word-spacing-xl">
          A - B - C - D - E -
          {' '}
          <br className="inline xs:hidden md:inline lg:hidden" />
          F -
          {' '}
          <InputWrapper>
            <input
              className={inputClass}
              defaultValue="GERALD"
              {...register('wordOne', formOptionsForWordInput)}
            />
          </InputWrapper>
        </span>
        <span>
          and your mom
          <br />
          and your sister and your job
          <br />
          And your broke-ass car
          <br />
          and that sh*t you call art
        </span>
        {errors.wordOne && <Error errorType={errors.wordOne.type} />}
      </div>
      <input
        type="submit"
        className="w56 px-5 py-0.5 w-56 bg-primaryDarker text-secondary uppercase cursor-pointer"
        value="Submit"
        custom-link-name="Submit Button"
      />
    </form>
  );
}

export default WordsForm;
