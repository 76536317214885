import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { formWaveSurferOptions } from '../lib/wavesurfer';
import PlayerButtons from './PlayerButtons';

interface GeneratedSnippetPlayerProps {
  url: string;
}

function GeneratedSnippetPlayer({ url }: GeneratedSnippetPlayerProps): JSX.Element {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer|null>(null);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const element = waveformRef.current;

    if (element) {
      const options = formWaveSurferOptions(element);

      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(url);

      wavesurfer.current.on('finish', () => {
        wavesurfer.current?.seekTo(0);
        setIsPlaying(false);
      });

      return () => {
        wavesurfer.current?.destroy();
      };
    }

    return undefined;
  }, [url]);

  const playPause = (): void => {
    wavesurfer.current?.playPause();
    setIsPlaying(!isPlaying);
  };

  const stop = (): void => {
    wavesurfer.current?.stop();
    setIsPlaying(false);
  };

  return (
    <div className="w-full md:w-5/6">
      <div className="mb-5" ref={waveformRef} />
      <div className="flex justify-center">
        <PlayerButtons isPlaying={isPlaying} playPause={playPause} stop={stop} />
      </div>
    </div>
  );
}

export default GeneratedSnippetPlayer;
