import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { AUDIO_FILE_LOCATION } from '../config';
import { formWaveSurferOptions } from '../lib/wavesurfer';
import PlayerButtons from './PlayerButtons';

interface CreatingASnippetPlayerProps {
  start: () => void;
  pause: () => void;
  stop: () => void;
}

function CreatingASnippetPlayer({
  start,
  pause,
  stop,
}: CreatingASnippetPlayerProps): JSX.Element {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer|null>(null);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const element = waveformRef.current;

    if (element) {
      const options = formWaveSurferOptions(element);

      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(AUDIO_FILE_LOCATION);
      wavesurfer.current.setMute(true);
      wavesurfer.current.toggleInteraction();

      wavesurfer.current.on('finish', () => {
        wavesurfer.current?.seekTo(0);
        setIsPlaying(false);
      });

      return () => {
        wavesurfer.current?.destroy();
      };
    }

    return undefined;
  }, [waveformRef]);

  const playPauseWithWaveform = (): void => {
    if (isPlaying) {
      wavesurfer.current?.pause();
      pause();
    } else {
      wavesurfer.current?.play();
      start();
    }

    setIsPlaying(!isPlaying);
  };

  const stopWithWaveform = (): void => {
    stop();
    wavesurfer.current?.stop();
    setIsPlaying(false);
  };

  return (
    <div className="w-full md:w-5/6">
      <div className="mb-5" ref={waveformRef} />
      <div className="flex justify-center mt-2">
        <PlayerButtons
          isPlaying={isPlaying}
          playPause={playPauseWithWaveform}
          stop={stopWithWaveform}
        />
      </div>
    </div>
  );
}

export default CreatingASnippetPlayer;
