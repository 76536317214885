/* eslint-disable no-console */
import React from 'react';
import Home from './containers/Home';
import View from './containers/View';

function App(): JSX.Element {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const itemId = params?.fu;

  return (
    <main className="w-screen h-screen min-w-320px min-h-650px overflow-y-auto overflow-x-hidden">
      <section className="w-full flex flex-col h-full md:flex-row">
        {itemId ? (
          <View itemId={itemId} />
        ) : (
          <Home />
        )}
      </section>
    </main>
  );
}

export default App;
