import React from 'react';
import { ReactComponent as IconYouTube } from '../assets/img/icon-group-44.svg';
import { ReactComponent as IconTwitter } from '../assets/img/icon-ionic-logo-twitter.svg';
import { ReactComponent as IconApple } from '../assets/img/icon-metro-apple.svg';
import { ReactComponent as IconInstagram } from '../assets/img/icon-simple-instagram.svg';
import { ReactComponent as IconSpotify } from '../assets/img/icon-simple-spotify.svg';
import { ReactComponent as IconFacebook } from '../assets/img/icon-zocial-facebook.svg';

const urlFacebook = 'https://facebook.com/GayleOfficial';
const urlTwitter = 'https://twitter.com/whoisgayle';
const urlInstagram = 'https://www.instagram.com/gayle';
const urlSpotify = 'https://open.spotify.com/artist/2VSHKHBTiXWplO8lxcnUC9';
const urlYouTube = 'https://www.youtube.com/channel/UCHD0Fx1MLWyQhAlQZw-tv9w';
const urlApple = 'https://music.apple.com/us/artist/gayle/1474403712';

function FooterIcons(): JSX.Element {
  return (
    <div
      className="flex max-w-lg pb-2 sm:pb-3 px-8 mt-5 md:mt-2"
    >
      <a
        className="mx-2 md:mx-5"
        href={urlFacebook}
        target="_blank"
        rel="noreferrer"
        data-track="facebook-ftr"
      >
        <IconFacebook />
      </a>
      <a
        className="mx-2 md:mx-5"
        href={urlTwitter}
        target="_blank"
        rel="noreferrer"
        data-track="twitter-ftr"
      >
        <IconTwitter />
      </a>
      <a
        className="mx-2 md:mx-5"
        href={urlInstagram}
        target="_blank"
        rel="noreferrer"
        data-track="instagram-ftr"
      >
        <IconInstagram />
      </a>
      <a
        className="mx-2 md:mx-5"
        href={urlSpotify}
        target="_blank"
        rel="noreferrer"
        data-track="spotify-ftr"
      >
        <IconSpotify />
      </a>
      <a
        className="mx-2 md:mx-5"
        href={urlYouTube}
        target="_blank"
        rel="noreferrer"
        data-track="youtube-ftr"
      >
        <IconYouTube />
      </a>
      <a
        className="mx-2 md:mx-5"
        href={urlApple}
        target="_blank"
        rel="noreferrer"
        data-track="apple-music-ftr"
      >
        <IconApple />
      </a>
    </div>
  );
}

export default FooterIcons;
