/* eslint-disable import/no-unresolved */
import { Mp3MediaRecorder } from 'mp3-mediarecorder';
import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Mp3RecorderWorker from 'workerize-loader!./worker'; // eslint-disable-line import/no-webpack-loader-syntax
import Tone from '../lib/tone';

export interface RecorderAndDestination {
  mediaRecorder: Mp3MediaRecorder;
  mediaStreamDestination: MediaStreamAudioDestinationNode;
}

type OnRecordingFinishedFunc = ((blob: Blob) => void)|undefined;

type UseRecorder = {
  ready: boolean,
  recorder: RecorderAndDestination|null,
};

const useRecorder = (
  onRecordingFinished: OnRecordingFinishedFunc = undefined,
): UseRecorder => {
  const [recorder, setRecorder] = useState<RecorderAndDestination|null>(null);
  const recordingChunks = useRef<BlobPart[]>([]);

  useEffect(() => {
    async function setupAudioRecording(): Promise<void> {
      // eslint-disable-next-line max-len
      const mediaStreamDestination = Tone.context.createMediaStreamDestination();

      const mediaRecorder = new Mp3MediaRecorder(
        mediaStreamDestination.stream,
        { worker: Mp3RecorderWorker() },
      );

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        recordingChunks.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(recordingChunks.current, { type: 'audio/mpeg' });

        if (onRecordingFinished) {
          onRecordingFinished(blob);
        }

        recordingChunks.current.length = 0;
      };

      setRecorder({ mediaRecorder, mediaStreamDestination });
    }

    setupAudioRecording();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ready = !!recorder;

  return { ready, recorder };
};

export default useRecorder;
