import { BlobUrlWithRevoke } from '../types/misc';

export function isEmpty<T>(obj: T): boolean {
  return Object.keys(obj).length === 0;
}

export function generateUrl(itemId: string): string {
  return process.env.NODE_ENV === 'development'
    ? `localhost:3007/?fu=${itemId}`
    : `https://www.abcde-fu.com/?fu=${itemId}`;
}

export function sleep(ms:number): Promise<unknown> {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function createBlobUrlObjectFromBlob(blob: Blob): BlobUrlWithRevoke {
  const url = URL.createObjectURL(blob);

  return {
    url,
    revoke: () => URL.revokeObjectURL(url),
  };
}
