// eslint-disable-next-line import/no-unresolved
import { WaveSurferParams } from 'wavesurfer.js/types/params';

export const formWaveSurferOptions = (ref: HTMLDivElement): WaveSurferParams => ({
  container: ref,
  waveColor: '#dedede',
  progressColor: '#FC6E00',
  cursorColor: '#FC6E00',
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 90,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
});
