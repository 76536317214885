/* eslint-disable no-console */
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ArtworkVideoButton from '../components/ArtworkVideoButton';
import CreatingASnippetPlayer from '../components/CreatingASnippetPlayer';
import GeneratedSnippetPlayer from '../components/GeneratedSnippetPlayer';
import Loading from '../components/Loading';
import ShareOptions from '../components/ShareOptions';
import StepTitle from '../components/StepTitle';
import YourLyrics from '../components/YourLyrics';
import { GET_SIGNED_URL, SNIPPETS_TABLE_URL } from '../config';
import useRecorder from '../hooks/useRecorder';
import { getTextToSpeechSampleBlobUrlObject } from '../lib/googleTTS';
import { sleep } from '../lib/misc';
import Tone, {
  connectToDestination,
  connectToRecorderAndDestination,
  createPlayer,
  createSamplerFromBlobUrl,
} from '../lib/tone';
import { AppState, APP_STATE } from '../types/appState';
import { InstrumentsRef } from '../types/tone';
import { NewSnippetsTableItem, Words } from '../types/words';

const scheduleSamples = (instruments: InstrumentsRef): void => {
  Tone.Transport.cancel();

  Tone.Transport.bpm.value = 122;
  instruments.player.sync().start(0);

  Tone.Transport.schedule(() => {
    instruments.wordOneSampler.triggerAttackRelease(['C4'], 8);
  }, '00:06:00');

  Tone.Transport.schedule(() => {
    instruments.wordOneSampler.triggerAttackRelease(['C4'], 8);
  }, '00:22:00');

  Tone.Transport.schedule(() => {
    instruments.player.stop();
    Tone.Transport.stop();
  }, '00:24:00');
};

interface SnippetContainerProps {
  chosenWords: Words;
  backToStepOneOnClick: () => void;
}

interface SnipperContainerState {
  step: AppState;
  s3link: string;
  shareId: string;
  errorMessage: string;
}

function SnippetContainer({
  chosenWords,
  backToStepOneOnClick,
}: SnippetContainerProps): JSX.Element|null {
  const [state, setState] = useState<SnipperContainerState>({
    step: APP_STATE.INITIAL,
    s3link: '',
    shareId: '',
    errorMessage: '',
  });
  const instrumentsRef = useRef<InstrumentsRef|null>(null);
  const recordingInstrumentsRef = useRef<InstrumentsRef|null>(null);

  const recorder = useRecorder(async (blob): Promise<boolean> => {
    setState({ ...state, step: APP_STATE.UPLOADING });

    try {
      const signedUrlResponse = await axios.get(GET_SIGNED_URL);

      if (signedUrlResponse.status === 200) {
        const { uploadURL } = signedUrlResponse.data;
        const uploadURLFileDestination = uploadURL.split('?')[0];

        const uploadToS3Response = await axios(uploadURL, {
          method: 'put',
          data: blob,
        });

        if (uploadToS3Response.status === 200) {
          const dataToSave: NewSnippetsTableItem = {
            wordOne: chosenWords.wordOne,
            url: uploadURLFileDestination,
          };

          const saveInDbResponse = await axios(SNIPPETS_TABLE_URL, {
            method: 'put',
            data: dataToSave,
          });

          if (saveInDbResponse.status === 200) {
            setState({
              ...state,
              step: APP_STATE.GENERATED,
              s3link: uploadURLFileDestination,
              shareId: saveInDbResponse.data.itemId,
            });

            return true;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    if (instrumentsRef.current) {
      scheduleSamples(instrumentsRef.current);
    }

    setState({
      ...state,
      step: APP_STATE.LISTEN,
      errorMessage: 'There was a problem uploading your snippet. Redirecting to PREVIEW page',
    });

    return false;
  });

  useEffect(() => {
    if (
      recorder.ready
      && recorder.recorder
      && state.step === APP_STATE.INITIAL
    ) {
      const setUpToneJSInstruments = async (): Promise<void> => {
        try {
          const player = createPlayer();

          const wordOneBlob = await getTextToSpeechSampleBlobUrlObject(
            chosenWords.wordOne,
          );

          const wordOneSampler = createSamplerFromBlobUrl(wordOneBlob.url);

          instrumentsRef.current = {
            player,
            wordOneSampler,
          };

          connectToDestination(instrumentsRef.current);

          const recordingPlayer = createPlayer();
          const recordingWordOneSampler = createSamplerFromBlobUrl(wordOneBlob.url);

          recordingInstrumentsRef.current = {
            player: recordingPlayer,
            wordOneSampler: recordingWordOneSampler,
          };

          if (recorder.recorder) {
            connectToRecorderAndDestination(
              recordingInstrumentsRef.current,
              recorder.recorder.mediaStreamDestination,
            );
          }

          wordOneBlob.revoke();

          scheduleSamples(instrumentsRef.current);

          setState({ ...state, step: APP_STATE.LISTEN, errorMessage: '' });
        } catch (error) {
          console.log(error);

          setState({
            ...state,
            errorMessage: 'There was a problem fetching the voice samples. Please refresh the page.',
          });
        }
      };

      setUpToneJSInstruments();
    }

    return () => {
      Tone.Transport.cancel();
      instrumentsRef.current?.player.dispose();
      instrumentsRef.current?.wordOneSampler.dispose();
      recordingInstrumentsRef.current?.player.dispose();
      recordingInstrumentsRef.current?.wordOneSampler.dispose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder.ready]);

  const start = (): void => {
    if (Tone.context.state !== 'running') {
      Tone.context.resume();
    }

    Tone.Transport.start();
  };

  const pause = (): void => {
    Tone.Transport.pause();
  };

  const stop = (): void => {
    Tone.Transport.stop();
  };

  const record = async (): Promise<void> => {
    if (!recordingInstrumentsRef.current) {
      console.error('Cannot record: recordingInstrumentsRef is null');
      return;
    }

    // ensure audio isn't playing and chedule Transport with recording instruments
    stop();
    await sleep(300);

    scheduleSamples(recordingInstrumentsRef.current);

    Tone.Transport.scheduleOnce(() => {
      recorder.recorder?.mediaRecorder.stop();
    }, '00:24:00');

    recorder.recorder?.mediaRecorder.start();
    start();
    setState({ ...state, step: APP_STATE.RECORDING });
  };

  if (state.step === APP_STATE.INITIAL) {
    return (
      state.errorMessage ? (
        <div className="flex flex-col items-center">
          <StepTitle text="Loading samples..." />
          <p className="border border-red-500 p-5">
            {state.errorMessage}
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <Loading text="Loading samples..." />
        </div>
      )
    );
  }

  if (state.step === APP_STATE.LISTEN) {
    return (
      <div className="flex flex-col items-center">
        <StepTitle text="Preview" />
        <YourLyrics chosenWords={chosenWords} />
        <CreatingASnippetPlayer start={start} pause={pause} stop={stop} />
        <button
          type="button"
          className={`
            w56 mt-5 px-5 py-0.5 w-56
            bg-primaryDarker text-secondary uppercase
          `}
          onClick={record}
          custom-link-name="Create Recording Button"
        >
          Create video
        </button>
        {backToStepOneOnClick && (
        <button
          type="button"
          className={`
            w56 mt-5 px-5 py-0.5 w-56
            bg-secondary text-primaryDarker
            border border-primaryDarker uppercase
          `}
          onClick={backToStepOneOnClick}
          custom-link-name="Edit Lyrics Button"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          Edit lyrics
        </button>
        )}
        {state.errorMessage && (
          <p className="border border-red-500 px-5 py-3 mt-5 w-72">
            {state.errorMessage}
          </p>
        )}
      </div>
    );
  }

  if (state.step === APP_STATE.RECORDING) {
    return (
      <div className="flex flex-col items-center">
        <Loading text="Recording..." />
      </div>
    );
  }

  if (state.step === APP_STATE.UPLOADING) {
    return (
      <div className="flex flex-col items-center">
        <Loading text="Uploading..." />
      </div>
    );
  }

  if (state.step === APP_STATE.GENERATED) {
    return (
      <div className="flex flex-col items-center">
        <StepTitle text="Your lyrics" />
        <YourLyrics chosenWords={chosenWords} />
        <GeneratedSnippetPlayer url={state.s3link} />
        <ShareOptions shareId={state.shareId} s3link={state.s3link} />
        <ArtworkVideoButton snippetId={state.shareId} />
        <a
          className={`
            w56 mt-5 px-5 py-0.5 w-56 text-center
            bg-primaryDarker text-secondary uppercase
          `}
          href="https://www.youtube.com/watch?v=NaFd8ucHLuo"
          target="_blank"
          rel="noreferrer"
          custom-link-name="Listen Button"
        >
          Listen to abcdefu
        </a>
        <button
          type="button"
          className={`
              w56 mt-5 px-5 py-0.5 w-56
              bg-secondary text-primaryDarker
              border border-primaryDarker uppercase
            `}
          onClick={backToStepOneOnClick}
          custom-link-name="Go Again Button"
        >
          Go again
        </button>
      </div>
    );
  }

  return null;
}

export default SnippetContainer;
