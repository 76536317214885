import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

interface AnnouncementMessageProps {
  text: string;
}

function AnnouncementMessage({ text }: AnnouncementMessageProps): JSX.Element|null {
  const [isVisible, setIsVisible] = useState(true);
  const [styles] = useSpring(() => ({
    opacity: 0,
    from: { opacity: 1 },
    config: { duration: 2000 },
    delay: 4000,
    onRest: () => setIsVisible(false),
  }));

  if (!isVisible) {
    return null;
  }

  return (
    <animated.div
      className={`
        border border-red-500
        px-5 pb-2 pt-1 w-60 text-white self-center
        text-center
      `}
      style={styles}
    >
      <span className="text-xs">{text}</span>
    </animated.div>
  );
}

export default AnnouncementMessage;
