import React from 'react';
import gayleLogo from '../assets/img/logo.png';

function LogoHeader(): JSX.Element {
  return (
    <div className="flex justify-between items-center py-5 md:p-5">
      <img src={gayleLogo} alt="GAYLE logo" className="h-4 md:h-6" />
      <h1 className="text-base md:text-xl">ABCDEFU</h1>
    </div>
  );
}

export default LogoHeader;
