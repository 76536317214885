import React from 'react';
import { ReactComponent as DownloadIconCircle } from '../assets/img/icon-download-circle.svg';
import { ReactComponent as IconFacebook } from '../assets/img/icon-share-facebook.svg';
import { ReactComponent as IconTwitter } from '../assets/img/icon-share-twitter.svg';
import { generateUrl } from '../lib/misc';
import CopyToClipboardButton from './CopyToClipboardButton';

interface ShareOptionsProps {
  shareId: string;
  s3link: string;
}

function ShareOptions({ shareId, s3link }: ShareOptionsProps): JSX.Element {
  const generatedUrl = generateUrl(shareId);
  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${generatedUrl}&cmpid=220125/gayle/wmi/scl/facebook/lan/but/ww/abcdefu-rec-gen-fb-share`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&url=${generatedUrl}&text=A%20-%20B%20-%20C%20-%20D%20-%20E%20-%20F%20...?&?cmpid=220125/gayle/wmi/scl/twitter/lan/but/ww/abcdefu-rec-gen-tw-share`;

  return (
    <div className="flex justify-between text-primaryDarker w-72 mt-6 mb-1">
      <CopyToClipboardButton shareId={shareId} />
      <button
        type="button"
        onClick={() => window.open(
          fbShareUrl,
          'targetWindow',
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=300`,
        )}
        custom-link-name="Facebook Share"
      >
        <IconFacebook className="fill-primaryDarker" />
      </button>
      <button
        type="button"
        onClick={() => window.open(
          twitterShareUrl,
          'targetWindow',
          `toolbar=no,
          location=no,
          status=no,
          menubar=no,
          scrollbars=yes,
          resizable=yes,
          width=500,
          height=300`,
        )}
        custom-link-name="Twitter Share"
      >
        <IconTwitter className="fill-primaryDarker" />
      </button>
      <a
        href={s3link}
        target="_blank"
        download={`abcdefu-${Date.now()}.mp3`}
        rel="noreferrer"
        custom-link-name="Download Button"
      >
        <DownloadIconCircle />
      </a>
    </div>
  );
}

export default ShareOptions;
