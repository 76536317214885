import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popover } from 'react-tiny-popover';
import { generateUrl, sleep } from '../lib/misc';

interface CopyToClipboardButtonProps {
  shareId: string;
}

function CopyToClipboardButton({ shareId }: CopyToClipboardButtonProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const generatedUrl = generateUrl(shareId);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
      content={(
        <div className="p-2 bg-primaryDarker rounded uppercase">
          <input
            type="text"
            className="bg-secondary text-primary border border-primaryDarker p-1 text-xs mr-2 w-32"
            value={generatedUrl}
            readOnly
          />
          {!isCopied ? (
            <CopyToClipboard
              text={generatedUrl}
              onCopy={async () => {
                setIsCopied(true);
                await sleep(500);

                setIsPopoverOpen(false);
              }}
            >
              <button type="button" className="uppercase">
                Copy share url
                {' '}
                <FontAwesomeIcon icon={faCopy} className="text-lg" />
              </button>
            </CopyToClipboard>
          ) : (
            <span className="uppercase">Copied!</span>
          )}
        </div>
      )}
    >
      <button
        type="button"
        className="uppercase"
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        Share url
      </button>
    </Popover>
  );
}
export default CopyToClipboardButton;
