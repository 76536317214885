import React from 'react';
import { Words } from '../types/words';

interface YourLyricsProps {
  chosenWords: Words;
}

function YourLyrics({
  chosenWords,
}: YourLyricsProps): JSX.Element {
  const { wordOne } = chosenWords;

  return (
    <div
      className={`\
        w-full md:w-5/6 mb-5 p-5 lg:pl-5per border border-primaryDarker
        uppercase line-height-lg md:line-height-xl
        text-base md:text-xl lg:text-2xl word-spacing-md
      `}
    >
      A - B - C - D - E -
      {' '}
      <br className="inline xs:hidden md:inline lg:hidden" />
      F -
      {' '}
      <span className="text-primaryDarker pl-1">{wordOne}</span>
      <br />
      and your mom
      <br />
      and your sister and your job
      <br />
      And your broke-ass car
      <br />
      and that sh*t you call art
      <br />
      F*ck
      {' '}
      <span className="text-primaryDarker pl-1">{wordOne}</span>
    </div>
  );
}

export default YourLyrics;
