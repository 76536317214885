import * as Tone from 'tone';
import { AUDIO_FILE_LOCATION } from '../config';
import { InstrumentsRef } from '../types/tone';

export const createSamplerFromBlobUrl = (blobUrl: string): Tone.Sampler => {
  const sampler = new Tone.Sampler({
    urls: { C4: blobUrl },
    release: 1,
  });

  return sampler;
};

export const createPlayer = (): Tone.Player => {
  const player = new Tone.Player(AUDIO_FILE_LOCATION);
  player.volume.value = -4;

  return player;
};

export const connectToDestination = (instruments: InstrumentsRef): void => {
  instruments.player.toDestination();
  instruments.wordOneSampler.toDestination();
};

export const connectToRecorderAndDestination = (
  instruments: InstrumentsRef,
  recordingDestination: MediaStreamAudioDestinationNode,
): void => {
  instruments.player.connect(recordingDestination).toDestination();
  instruments.wordOneSampler.connect(recordingDestination).toDestination();
};

export default Tone;
